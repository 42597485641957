import { mapMutations } from 'vuex';
import { WithProject } from '@/mixins';

export default (withServices = false) => ({
  mixins: [WithProject(withServices)],
  watch: {
    '$route.params': 'updateCollection',
    ready: { handler: 'updateCollection', immediate: true },
  },
  methods: {
    ...mapMutations('ui', { setCollectionIndex: 'set-collection-index' }),

    updateCollection() {
      if (!this.projectReady) return;

      this.setCollectionIndex({
        collectionIds: this.project.servicesIds,
        type: 'services',
      });
    },
  },
});
