<template>
  <div class="v-projects-show-services-index">
    <router-view />
  </div>
</template>

<script>
import { ViewWithProject } from '@/views/mixins';
import { guards } from '@/router/routes/projects';

export default {
  name: 'VProjectsShowServicesIndex',
  mixins: [ViewWithProject(true)],
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'projects-show-services-index' && to.name === from.name)
      return guards.services.index.beforeEnter(to, from, next);

    next();
  },
  computed: {
    ready() {
      return this.projectReady;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
+media-up(md)
  .v-projects-show-services-index
    height: 100%
</style>
