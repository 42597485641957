import { mapMutations } from 'vuex';
import { WithAllProjects } from '@/mixins';

export default {
  mixins: [WithAllProjects],
  watch: {
    '$route.params': 'updateCollection',
    ready: { handler: 'updateCollection', immediate: true },
  },
  methods: {
    ...mapMutations('ui', { setCollectionIndex: 'set-collection-index' }),

    updateCollection() {
      if (!this.allProjectsReady) return;

      this.setCollectionIndex({
        collectionIds: _.map(this.projects, 'id'),
        type: 'projects',
      });
    },
  },
};
